
var MAX_CHASHKA = 38;
var MAX_CHASHKA_S = 37;
var MIN_CHASHKA = 0;

var MIN_SIZE = 63;
// var MAX_SIZE = 122;
var MAX_SIZE = 141;

/** /
var MIN_BSIZE = 84;
var MAX_BSIZE = 136;
/**/
var MIN_BSIZE2 = 88;
var LAST_BSIZE2 = 120;
var MAX_BSIZE2 = 125;
 
var MIN_TSIZE = 55;
var MAX_TSIZE = 115;

var MIN_BBSIZE = 80;
var MAX_BBSIZE = 145;

var MIN_KGSIZE = 83;
var MAX_KGSIZE = 130;

var MIN_KPGSIZE = 70;
var MAX_KPGSIZE = 110;

var MIN_KBSIZE = 96;
var MAX_KBSIZE = 124;

actual_sizes = new Array ( 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37 );
ch = { 12: 'AA', 13: 'A', 15: 'B', 17: 'C', 19: 'D', 21: 'E', 23: 'F', 25: 'G', 27: 'H', 29: 'I', 31: 'J', 33: 'K', 35: 'L', 37: 'M' };

actual_sizes2 = new Array ( 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120 );
var actual_sizes2_2 = { 63:65, 68:70, 73:75, 78:80, 83:85, 88:90, 93:95, 98:100, 103:105, 108:110, 113:115, 118:120 };

actual_sizes3 = new Array ( 84, 88, 92, 96, 100, 104, 108, 112, 116, 120, 124, 128, 132, 136 );
actual_sizes3_2 = new Array (  88, 90, 94, 98, 102, 106, 112, 116, 120);
trusy = { 88: 'XS(34)', 90: 'S(36)', 94: 'M(38)', 98: 'L(40)', 102: 'XL(42)', 106: 'XXL(44)', 112: '3XL(46)', 116: '4XL(48)', 120: '5Xl(50)' };

actual_sizes4 = new Array ( 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115 );
actual_sizes5 = new Array ( 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145 );

actual_sizes6 = new Array ( 83, 85, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 98, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 128, 130 );
actual_sizes7 = new Array ( 65, 70, 75, 80, 85, 90, 95, 100, 105, 110 );
actual_sizes8 = new Array ( 96, 100, 104, 108, 112, 116, 120, 124 );

kup = new Array ( 36, 38, 40, 42, 44, 46, 48, 50, 52, 54 );



function obj_sizes( arr, val ) {
    f = false;
    obj = {
        border: 0,
        eq: 0
    }
    for ( i = 0; i < arr.length; i++ ) {
        if ( ( arr[i] == val ) ) {
            obj = {
                border: 0,
                eq: val,
                i: i
            }
            f = true;
            break;
        } else {
            if ( ( arr[i] < val ) && ( arr[i+1] > val ) ) {
                if ( Math.abs( arr[i] - val ) < Math.abs( arr[i+1] - val ) )  { 
                    obj = {
                        border: arr[i],
                        eq: 0,
                        i: i
                    }
                    f = true;
                    break;
                } else {
                    obj = {
                        eq: 0,
                        border: arr[i+1],
                        i: i+1
                    }
                    f = true;
                    break;
                }
            }
        }
    }
    return obj;
}

function size_1_o() {
    size1 = '';
    val = document.forms['fsize'].s1.value - document.forms['fsize'].s2.value;

    if ( ( val < MIN_CHASHKA ) || ( val > MAX_CHASHKA ) ) return size1;
	
    if ( ( document.forms['fsize'].s2.value < MIN_SIZE ) || ( document.forms['fsize'].s2.value > MAX_SIZE ) ) return size1;
	
    chasha = obj_sizes( actual_sizes, val );
    size = obj_sizes( actual_sizes2, document.forms['fsize'].s2.value );
// console.log(size);
    if ( chasha.border ) {
        if ( size.border ) {
            size1 = size.border+ch[chasha.border];
        } else if ( size.eq ) {
            size1 = size.eq+ch[chasha.border];
        }
    } else if ( chasha.eq ) {
        if ( size.border ) {
            size1 = size.border+ch[chasha.eq];
        } else {
            size1 = size.eq+ch[chasha.eq];
        }
    } else if ( val < 13 ) {
        if ( size.border ) {
            size1 = size.border+ch[12];
        } else if ( size.eq ) {
            size1 = size.eq+ch[12];
        }
    }
    
    return size1;
}

//трусы
function size_2_o() {
    size2 = '';
    if ( ( document.forms['fsize'].s4.value < MIN_BSIZE ) || ( document.forms['fsize'].s4.value > MAX_BSIZE ) ) return size2;
    size = obj_sizes( actual_sizes3, document.forms['fsize'].s4.value );
    if ( size.border ) {
        size2 = size.border;
    } else if ( size.eq ) {
        size2 = size.eq;
    }
    return size2;
}

function size_1() {
  let  size1 = '';
  let  size2 = '';
	setSize = parseInt(document.forms['fsize'].s2.value);
    cup = parseInt(document.forms['fsize'].s1.value) - setSize;
	
    if ( ( cup < MIN_CHASHKA ) || ( cup > MAX_CHASHKA ) ) return size1;
	
    if ( ( setSize < MIN_SIZE ) || ( setSize > MAX_SIZE ) ) return size1;
	
	if(cup < 13) {
		size2 = ch[12];
	} else if(cup == MAX_CHASHKA) {
		size2 = ch[MAX_CHASHKA_S];
	} else {
		$.each(ch, function(size, name) {
			if(cup == size) {
				size2 = name;
			} else if(cup < size) {
				return false;
			} else if(cup > size) {
				size2 = name;
			}
		});
	}
	$.each(actual_sizes2_2, function(size, name) {
		if(setSize == size) {
			size1 = name;
		} else if(setSize < size) {
			return false;
		} else if(setSize > size) {
			size1 = name;
		}
	});
    
    return size1+' '+size2;
}
//трусы
function size_2() {
    let size2 = '';
	let setSize = parseInt(document.forms['fsize'].s4.value);
    if ( ( setSize < MIN_BSIZE2 ) || ( setSize > MAX_BSIZE2 ) ) return size2;
	
	if(setSize == MIN_BSIZE2) {
		size2 = trusy[MIN_BSIZE2];
	} else if(setSize >= LAST_BSIZE2) {
		size2 = trusy[LAST_BSIZE2];
	} else {
		$.each(trusy, function(size, name) {
			if(setSize == size) {
				size2 = name;
			} else if(setSize < size) {
				return false;
			} else if(setSize > size) {
				size2 = name;
			}
		});
	}
// console.log(size2);
	
    return size2;
}

//трусы карсетные
function size_3() {
    size3 = '';
    if ( ( document.forms['fsize'].s3.value < MIN_TSIZE ) || ( document.forms['fsize'].s3.value > MAX_TSIZE ) ) return size3;
    if ( ( document.forms['fsize'].s4.value < MIN_BBSIZE ) || ( document.forms['fsize'].s4.value > MAX_BBSIZE ) ) return size3;
    
    t_size = obj_sizes( actual_sizes4, document.forms['fsize'].s3.value );
    b_size = obj_sizes( actual_sizes5, document.forms['fsize'].s4.value );
    
    if ( t_size.border ) {
        if ( b_size.border ) {
            size3 = t_size.border+'-'+b_size.border;
        } else if ( b_size.eq ) {
            size3 = t_size.border+'-'+b_size.eq;
        }
    } else if ( t_size.eq ) {
        if ( b_size.border ) {
            size3 = t_size.eq+'-'+b_size.border;
        } else if ( b_size.eq ) {
            size3 = t_size.eq+'-'+b_size.eq;
        }
    }
    
    return size3;
}

function size_4() {
    size4='';

    val = document.forms['fsize'].s1.value - document.forms['fsize'].s2.value;
    if ( ( val < MIN_CHASHKA ) || ( val > MAX_CHASHKA ) ) return size4;
    if ( ( document.forms['fsize'].s2.value < MIN_SIZE ) || ( document.forms['fsize'].s2.value > MAX_SIZE ) ) return size4;

    chasha = obj_sizes( actual_sizes, val );
    pg_size = obj_sizes( actual_sizes7, document.forms['fsize'].s2.value );
	
    if ( chasha.border ) {
            size4 = kup[pg_size.i]+ch[chasha.border];
    } else if ( chasha.eq ) {
            size4 = kup[pg_size.i]+ch[chasha.eq];
    } else if ( val < 13 ) {
            size4 = kup[pg_size.i]+ch[12];
    }

    return size4;
}


//Проверка ввода
function check ( id ) {
    exp = /[^0-9]/i;
    //Целое ли число ввели
    if ( exp.exec(document.forms['fsize'].elements[id].value) ) {
        str = new String(document.forms['fsize'].elements[id].value);
        document.forms['fsize'].elements[id].value = str.substring(0, str.length-1);
        return false
    } 
    
    return true
}
function calc( id ) {
    if ( !check( id ) ) {
    	document.getElementById('calc_warning').style.display = "block";
    } else {
        flag = false;
        
        if ( document.forms['fsize'].s1.value && document.forms['fsize'].s2.value ) {
            size = size_1();
            if ( size ) {
                document.getElementById('s1_value').innerHTML = size;
            } else {
                document.getElementById('s1_value').innerHTML = '';
            }
        }
                
        if ( document.forms['fsize'].s4.value ) {
            size = size_2();
            if ( size ) {
                document.getElementById('s2_value').innerHTML = size;
            } else {
               document.getElementById('s2_value').innerHTML = size;
            }
        }
        
        if ( document.forms['fsize'].s3.value && document.forms['fsize'].s4.value ) {
            size = size_3();
            if ( size ) {
                document.getElementById('s3_value').innerHTML = size;
            } else {
                document.getElementById('s3_value').innerHTML = size;
            }
        }

        if ( document.forms['fsize'].s2.value ) {
            size = size_4();
            if ( size ) {
                document.getElementById('s4_value').innerHTML = size;
            } else {
                document.getElementById('s4_value').innerHTML = '';
            }
        }  
        
    }
}

oldValueCalc=false;
function calcAll(){
	if (!oldValueCalc) oldValueCalc=document.getElementById('calc_warning').innerHTML;
	if (document.getElementById('calc_warning').innerHTML!=oldValueCalc) document.getElementById('calc_warning').innerHTML=oldValueCalc;
	calc('s1');
	calc('s2');
	calc('s3');
	calc('s4');
}
function gid(id){
	return document.getElementById(id);
}
function printSize(el){
	var getstr="?chest="+gid("s1").value;
	getstr+="&chestgirth="+gid("s2").value;
	getstr+="&waist="+gid("s3").value;
	getstr+="&thigh="+gid("s4").value;
	getstr+="&brassiere="+gid("s1_value").innerHTML;
	getstr+="&pants="+gid("s2_value").innerHTML;
	getstr+="&pantalette="+gid("s3_value").innerHTML;
	getstr+="&swimsuits="+gid("s4_value").innerHTML;
	el.href=el.href+getstr;
}
